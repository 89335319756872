<template>
	<v-expansion-panel v-if="canShowVersions" class="w-bordered-panel historic mb-2">
		<v-expansion-panel-content>
			<template v-slot:header>
				<v-flex v-t="'documents.history'" class="w-expansion-panel-title" />
			</template>
			<v-timeline align-top dense>
				<v-timeline-item
					v-for="(version, index) in documentVersions"
					:key="version.id"
					class="pointer"
					:color="isSelected(version) ? 'primary' : 'grey'"
					small
					@click.native.stop="changeVersion(version)"
				>
					<HistoricVersion
						:version="version"
						:version-number="getVersionNumber(index)"
						:with-workflows="!isTrash"
					>
						<template v-slot:badge="slotProps">
							<ValidationBadge v-model="documentNode" :version="version" v-bind="slotProps" />
						</template>
					</HistoricVersion>
				</v-timeline-item>
			</v-timeline>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>
<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import ApplicationDocumentVersionService from '@/services/ApplicationDocumentVersions/ApplicationDocumentVersionService'
import { mapState } from 'vuex'

export default {
	name: 'DocumentVersions',
	components: {
		HistoricVersion: () => ({
			component: import('@/components/Documents/Versions/HistoricVersion')
		}),
		ValidationBadge: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationBadge')
		})
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		document: {
			type: Object,
			required: false,
			default: null
		},
		isTrash: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Array, Object],
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			loadingVersions: false,
			isOpen: true
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		documentNode: {
			get: function () {
				return this.document
			},
			set: function (value) {
				this.$emit('document-changed', value)
			}
		},
		documentVersions: function () {
			let result = null
			if (this.document?.versions) {
				result = [...this.document.versions].sort((a, b) => b.id - a.id)
			}
			if (this.isAccountant && result) {
				result = result.map(version => {
					ApplicationDocumentVersionService.getApplicationDocumentVersions(this.vendorId, this.document, version).then(applicationDocumentVersions => {
						version.applicationDocumentVersions = applicationDocumentVersions.length > 0 ? applicationDocumentVersions : null
					})
					return version
				})
			}

			return result
		},
		canShowVersions: function () {
			return this.documentVersions?.length > 0
		}
	},
	watch: {
		document: {
			handler: function () {
				this.loadDocumentVersions()
			}
		},
		documentVersions: {
			handler: function () {
				this.selectLatestVersion()
			},
		}
	},
	methods: {
		loadDocumentVersions: function () {
			if (!this.loadingVersions && this.document) {
				this.loadingVersions = true
				this.service.getDocumentVersions(this.vendorId, this.document).finally(() => {
					this.model = this.document?.latestVersion
					this.loadingVersions = false
				})
			}
		},
		selectLatestVersion: function () {
			this.model = this.document?.latestVersion
		},
		getVersionNumber: function (index) {
			return this.documentVersions?.length - index
		},
		isSelected: function (version) {
			return this.model?.id === version.id || (this.model == null && this.documentVersions.length > 0 && this.documentVersions[0].id === version.id)
		},
		changeVersion: function (version) {
			this.model = version
		}
	}
}
</script>
<style scoped>
.historic .v-expansion-panel__header {
	padding-left: 1.25rem;
}
</style>
