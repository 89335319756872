// ============================================================================
// ApplicationDocumentVersionService
// -----------------------
// ApplicationDocumentVersion module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/ApplicationDocumentVersionApi'
// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			document: {
				applicationDocumentVersion: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, applicationDocumentVersionId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, applicationDocumentVersionId: applicationDocumentVersionId })
						return API.vendor.document.applicationDocumentVersion.read(vendorId, documentId, applicationDocumentVersionId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				version: {
					applicationDocumentVersions: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return API.vendor.document.version.applicationDocumentVersions.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					}
				}
			}
		}
	},
	service: {
		vendor: {
			document: {
				applicationDocumentVersion: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, applicationDocumentVersion, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, applicationDocumentVersion: applicationDocumentVersion })
						return Private.requests.vendor.document.applicationDocumentVersion.read(vendorId, documentId, applicationDocumentVersion.id, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				version: {
					applicationDocumentVersions: {
						create: NotImplementedYet,
						read: function (vendorId, document, version) {
							RequiredArguments({ vendorId: vendorId, document: document, version: version })
							const parameters = {}
							if (document.deleted_at) {
								if (!parameters.hasOwnProperty('filters')) {
									parameters.filters = {}
								}
								parameters.filters.with_trashed = true
							}
							return Private.requests.vendor.document.version.applicationDocumentVersions.read(vendorId, document.id, version.id, parameters)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					}
				}
			}
		}
	},
	store: {}
}
// -------
// Exports
// -------
export default {
	createApplicationDocumentVersion: Private.service.vendor.document.applicationDocumentVersion.create,
	getApplicationDocumentVersion: Private.service.vendor.document.applicationDocumentVersion.read,
	updateApplicationDocumentVersion: Private.service.vendor.document.applicationDocumentVersion.update,
	deleteApplicationDocumentVersion: Private.service.vendor.document.applicationDocumentVersion.delete,

	createApplicationDocumentVersions: Private.service.vendor.document.version.applicationDocumentVersions.create,
	getApplicationDocumentVersions: Private.service.vendor.document.version.applicationDocumentVersions.read,
	updateApplicationDocumentVersions: Private.service.vendor.document.version.applicationDocumentVersions.update,
	deleteApplicationDocumentVersions: Private.service.vendor.document.version.applicationDocumentVersions.delete
}
