// ============================================================================
// ApplicationDocumentVersionApi
// -------------------
// ApplicationDocumentVersion api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	vendor: {
		document: {
			version: {
				applicationDocumentVersions: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
						return Backend.GET(
							URLS.api.vendors.documents.versions.applicationDocumentVersion.uri,
							[vendorId, documentId, versionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},
			applicationDocumentVersion: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, applicationDocumentVersionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId, applicationDocumentVersionId: applicationDocumentVersionId })
					return Backend.GET(
						URLS.api.vendors.documents.applicationDocumentVersion.uri,
						[vendorId, documentId, applicationDocumentVersionId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	}
}
